import styled from "styled-components";

const Container = styled.div`
  max-width: 85vw;
  margin: 0 auto;
`;
const FullWidthContainer = styled.div`
  max-width: 1600px;
  margin: 0 auto;
`;

const Block = styled.section`
  /* overflow: hidden; */
  min-height: 500px;
  position: relative;
  z-index: 1000;
  background: #f8f8f8;
  overflow: hidden;
`;

const Button = styled.button`
  padding: 12px 24px;
  border-radius: 20px;
  font-weight: 500;
  letter-spacing: 0.04em;
  border: 0;
  outline: 0;
  text-transform: uppercase;
  font-size: 14px;
  background: none;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  transition: box-shadow 0.3s ease;

  ${({ outline }) =>
    outline &&
    `    
    box-shadow: inset 0px 0px 0px 2px #fff;
    transition: box-shadow 0.3s ease, transform 0.3s ease;

    &:hover {      
      box-shadow: inset 0px 0px 0px 2px #fff, 0px 0px 6px 2px #ffffff65;
      transform: scale(1.03)
    }
  `}

  ${({ primary }) =>
    primary &&
    `
    background: #ff9f1c;
    box-shadow: 0 0px 18px 4px #ff9f1c00;
    transition: box-shadow 0.3s ease, transform 0.3s ease;

    &:hover {
      box-shadow: 0 0px 18px 4px #ff9f1c5c;
      transform: scale(1.03)
    }
  `}

  ${({ solid }) =>
    solid &&
    `
    background: #fff;   
    color: #1b2033;    
    box-shadow: 0 0px 18px 4px #ffffff00;
    transition: box-shadow 0.3s ease, transform 0.3s ease;

    &:hover {
      box-shadow: 0 0px 18px 4px #ffffff5c;
      transform: scale(1.03)
    }
  `}

  ${({ large }) =>
    large &&
    `
  padding: 18px 30px;
  border-radius: 26px;
  `}

  & + button {
    margin-top: 10px;
  }
`;

const PreTitle = styled.h3`
  color: #2ec4b6;
  margin-bottom: 10px;
  text-align: center;
`;

const Title = styled.h2`
  font-size: 50px;
  font-weight: 800;
  color: #1b2033;
  margin-top: 10px;
  text-align: center;

  ${PreTitle} + & {
    margin-top: 0;
  }
`;

export { Container, Block, Button, FullWidthContainer, PreTitle, Title };
