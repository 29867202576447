const anchorTo = (link, i18n) => {
  if (link.indexOf("#") === -1 || typeof window === "undefined") return link;

  const [page, hash] = link.split("#");
  const urlLang = i18n.language !== "en" ? `/${i18n.language}` : "";

  if (window.location.pathname === `${urlLang}${page}`) return `#${hash}`;
  return link;
};

export default anchorTo;
