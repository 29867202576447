import React from "react";
import Helmet from "react-helmet";
import favicon from "../assets/favicon.ico";

const Layout = ({ children, pageTitle }) => {
  return (
    <>
      <Helmet
        title={pageTitle}
        meta={[
          { name: "description", content: "WeTechFood" },
          // { name: 'keywords', content: '....' },
        ]}
        link={[{ rel: "shortcut icon", type: "image/ico", href: `${favicon}` }]}
      />
      {children}
    </>
  );
};

export default Layout;
