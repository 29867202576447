import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { Container, Button } from "../components/ui";
import WeTechFoodLogo from "../assets/logo.svg";
import LanguageSelector from "../components/LanugageSelector";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";
import anchorTo from "../helper/anchorTo";

const Wrapper = styled.header`
  position: fixed;
  height: 65px;
  width: 100%;
  background: #fff;
  /* background: #000000cc; */
  z-index: 1000000;
  border-bottom: 1px solid #eee;

  & img {
    max-height: 40px;
  }
`;

const StyledBurger = styled.button`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    transition: all 0.3s ease;
    position: relative;
    transform-origin: 1px;
    background: #42526e;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }

  @media (max-width: 960px) {
    display: flex;
  }
`;

const Menu = styled.nav`
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  white-space: nowrap;

  @media (max-width: 960px) {
    position: fixed;
    top: 0;
    right: max(-90vw, -400px);
    bottom: 0;
    width: min(90vw, 400px);
    background: #fff;
    flex-direction: column;

    justify-content: flex-start;
    padding: 80px min(40px, 5vw) 50px 0;
    align-items: flex-start;

    transition: all 0.3s ease;
    transform: ${({ open }) => (open ? "translateX(-100%)" : "translateX(0)")};
    ${({ open }) =>
      open &&
      `
  border-left: 1px solid #eee;
    `}

    & > div {
      width: 100%;
      display: block;
      font-size: 20px;
      margin-bottom: 20px;
    }
    & > div > button {
      font-size: 18px;
      border-radius: 28px;
    }
  }
`;

const containerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
};

const MenuItem = styled.div`
  margin-left: 10px;
  border: 0;
  background: none;
  outline: none;
  color: #42526e;
  cursor: pointer;
  padding: 1px 6px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.085em;
  text-transform: uppercase;
  padding: 6px 20px;
  border-radius: 3px;
  background-color: #fff;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #ececec;
    & a {
      text-decoration: none !important;
    }
  }

  ${({ primary }) =>
    primary &&
    `
    background-color: #FF5555;
    & a {
      color: #fff;
    }
    &:hover {
      background-color: #ff7676;
    }
    
  `}
`;

const Anchor = styled(AnchorLink)`
  color: #42526e;
  text-decoration: none;
  font-weight: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const Header = () => {
  const { t, i18n } = useTranslation();
  const { changeLanguage } = useI18next();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Wrapper>
      <Container style={containerStyle}>
        <Link to="/">
          {/* <img src={weTechFoodLogo} alt="WeTechFood" /> */}
          <WeTechFoodLogo />
        </Link>
        <StyledBurger open={menuOpen} onClick={() => setMenuOpen((p) => !p)}>
          <div />
          <div />
          <div />
        </StyledBurger>
        <Menu open={menuOpen}>
          <MenuItem>
            <Anchor to={anchorTo("/#work", i18n)}>{t("menu.work")}</Anchor>
          </MenuItem>
          <MenuItem>
            <Anchor to={anchorTo("/#success", i18n)}>{t("menu.success")}</Anchor>
          </MenuItem>
          <MenuItem>
            <Anchor to={anchorTo("/#team", i18n)}>{t("menu.team")}</Anchor>
          </MenuItem>
          {/* <MenuItem style={{ marginLeft: 20 }}>
            <LanguageSelector
              currentLanguage={i18n.language}
              onLanguageChange={(newLang) => {
                changeLanguage(newLang);
              }}
            />
          </MenuItem> */}
          <MenuItem as="div" primary>
            <Anchor to={anchorTo("/detailedInfo/", i18n)}>{t("menu.info")}</Anchor>
          </MenuItem>
        </Menu>
      </Container>
    </Wrapper>
  );
};

export default Header;
