import React from "react";
import styled from "styled-components";
import Select, { components } from "react-select";
import FlagGB from "../assets/icons/flags/gb.svg";
import FlagPT from "../assets/icons/flags/pt.svg";
import FlagDE from "../assets/icons/flags/de.svg";

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 10px; */

  & svg {
    min-width: 20px;
    border-radius: 4px;
    height: 15px;
  }
`;

const LanguageSelector = ({ currentLanguage, onLanguageChange }) => {
  const languageOptions = [
    { value: "en", label: "EN", icon: <FlagGB /> },
    { value: "es", label: "ES", icon: <FlagPT /> },
    // { value: "de", label: "DE", icon: <FlagDE /> },
  ];

  const { Option } = components;
  const CustomSelectOption = (props) => (
    <Option {...props}>
      <OptionRow>
        {props.data.icon && props.data.icon}
        <span
          style={{
            paddingLeft: props.data.icon ? 5 : 0,
          }}
        >
          {props.data.label}
        </span>
      </OptionRow>
    </Option>
  );

  const CustomSelectValue = (props) => (
    <OptionRow>
      {/* {props.data.icon && props.data.icon} */}
      <span style={{ paddingLeft: props.data.icon ? 5 : 0 }}>
        {props.data.label}
      </span>
    </OptionRow>
  );

  return (
    <Select
      styles={{
        option: (p, data) => ({
          ...p,
          cursor: "pointer",
          color: data.isSelected ? "#fff" : "#000",
        }),
        indicatorsContainer: (provided) => ({
          ...provided,
          padding: 0,
          "& path": {
            fill: "#fff",
            transformOrigin: "center",
            transform: "scale(0.8)",
          },
          "& > div:nth-of-type(2)": {
            padding: 0,
          },
        }),
        indicatorContainer: (provided) => ({
          ...provided,
          padding: 0,
        }),
        indicatorSeparator: (provided) => ({
          display: "none",
        }),
        valueContainer: (p) => ({
          ...p,
          display: "flex",
          padding: "0",
          minWidth: "23px",
          flexWrap: "nowrap",
        }),
        control: (p) => ({
          ...p,
          cursor: "pointer",
          background: "none",
          border: 0,
          boxShadow: "none",
          padding: "0 8px",
          flexWrap: "nowrap",
          ":hover": {
            ":after": {
              content: '""',
              position: "absolute",
              width: "60%",
              left: "50%",
              top: "69%",
              height: "1px",
              background: "#fff",
              transform: "translateX(-58%)",
              "@media (max-width:960px)": {
                bottom: "15%",
                height: "5%",
                top: "auto",
              },
            },
          },
        }),
        container: (p) => ({
          ...p,
          margin: "0 -10px",
          "@media (max-width: 960px)": {
            margin: 0,
          },
        }),
      }}
      components={{
        Option: CustomSelectOption,
        SingleValue: CustomSelectValue,
      }}
      isSearchable={false}
      value={languageOptions.find((option) => option.value === currentLanguage)}
      options={languageOptions}
      onChange={(option) => {
        if (option !== null) onLanguageChange(option.value);
      }}
    />
  );
};

export default LanguageSelector;
